import {mapState} from "vuex";
import remove from "../../mixins/remove";

export default {
    name: 'TournamentsComponent',
    computed: {
        ...mapState({
            items: (state) => state.tournaments.items,
        }),
        headers() {
            return [
                {text: 'Name', value: "name"},
                {
                    text: 'Category',
                    value: "type",
                    align: "center",
                },
                {
                    text: 'Edit',
                    value: "actions",
                    align: "end",
                },
            ];
        },
    },
    methods: {
        removeItem() {
            this.$store.dispatch("tournaments/removeItem", this.selected);
            this.clearDialog();
        }
    },
    created() {
        this.$store.dispatch("tournaments/getItems");
    },
    mixins: [remove],
}
